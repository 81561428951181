import React, {useState, useEffect} from 'react'
import PropTypes from "prop-types"

const Tabs = (props) => {

  const [active, setActive] = useState(1);

  useEffect(() => {
    setActive(1);
  },[]);

  const handleActive = (key) => {
    if(props.tabchange !== undefined){
      props.tabchange(key);
    }
    setActive(parseInt(key));
  }

  return (
    <div className={`tabs ` + props.className}>
      <div className="row expanded tabs__deck">
        <div className="columns">
          <div className="row row--inner">
            <div className="columns">
              <ul className="tabs__tabs">
                {React.Children.map(props.children, child => {
                  return (
                    <li className={active === parseInt(child.key)? 'isActive' : ''}><button onClick={() => handleActive(child.key)}>{child.props.name}</button></li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {React.Children.map(props.children, child => {
        return (
          <>
            <div className={active === parseInt(child.key) ? `row expanded tabs__content` : `visually-hidden`}>
              <div className="columns">
                {child}
              </div>
            </div>
          </>
        )
      })}
    </div>
  )
}


Tabs.Pane = ({ children }) => children

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Tabs
