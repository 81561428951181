import React, {useState} from "react"
import { Link } from "gatsby"
import Slider from "react-slick-slider"
import SliderNextArrow from "../../components/template-partials/slider-components/slider-next-arrow"
import SliderPrevArrow from "../../components/template-partials/slider-components/slider-prev-arrow"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"
import FancyImage from "../../components/template-partials/global-components/fancy-image"
import NewlyDiagnosedSubmenu from "../../components/template-partials/newly-diagnosed/newly-diagnosed-submenu"

import {UpNextCard} from "../../components/template-partials/global-components/footer-cards"


import headingBg from "../../images/2.0-headerbg.jpg"
import headingRound from "../../images/newly-diagnosed-nav-image.jpg"

import line from "../../images/2.2.03_intoavein.png";
import injection from "../../images/2.2.02_injection.png";
import byMouth from "../../images/2.2.01_bymouth.png";

import port from "../../images/port4x.png";
import picc from "../../images/2.3.04_PICCline.png";
import broviac from "../../images/Broviac-Diagram.png";

import hospitalRoad from "../../images/1.3.03_hospitalrd.png";
import askForHelp from "../../images/2.3.04_askforhelp.png";
import keepNotes from "../../images/2.3.03_keepnotes.png";
import calendar from "../../images/2.3.02_calendar.png";

import favToy from "../../images/2.2.09_favtoy.png"
import comfyClothes from "../../images/2.2.07_comfortableclothing.png"
import toiletries from "../../images/2.2.11_toiletries.png"
import chargers from "../../images/2.2.08_chargers.png"
import snacks from "../../images/2.2.10_favoritesnack.png"
import pillowBlanket from "../../images/2.2.06_pillowblanket.png"

import hospitalStay from "../../images/2.3-hospital-stay.jpg";
import oncology from "../../images/2.3-oncology.jpg";
import insuranceCost from "../../images/2.3-insurance-cost.jpg";

import Tabs from "../../components/template-partials/global-components/tabs/tabs"
import TabbedCarousel from "../../components/template-partials/global-components/tabbed-carousel/tabbedCarousel"

import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Cool! Export your sass Vars from a top line and use them in JS!
import * as SassVars from '../../components/variables.module.scss';

const PreparingForTreatmentPage = () => {

  const [active, setActive] = useState(1);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: SliderNextArrow,
    prevArrow: SliderPrevArrow,
    responsive: [
      { breakpoint: 1000000, settings: 'unslick' },
      { breakpoint: 1024, settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }},
    ]
  };

  //Trick to refire slick on hidden tabby
  const tabChange =(key)=>{
    setActive(key);
  }

  return (
    <Layout>
      <SEO title="Preparing for Neuroblastoma Treatment" description="A neuroblastoma diagnosis will likely change the day-to-day schedule of your family. Planning for these changes early can be helpful. Learn about when and where your child will receive treatment, as well as tips on common nonmedical concerns such as work, school, and finances." />

      <InteriorPageHeader backgroundImage={headingBg} roundImage={headingRound} alt="Preparing for Treatment">
        <h1 className="section__heading section__heading--green h1">Preparing <em>for</em> Treatment</h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`Newly Diagnosed`}>
              <NewlyDiagnosedSubmenu/>
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons/>
              </div>
            </div>
            <div className="row">
              <div className="columns">
                <h2 className="h2 tc-barney jost-med">Preparing for Treatment</h2>
                <p>It is likely that your child has never been in the hospital before being diagnosed with neuroblastoma. You may not know what to expect, so the idea of starting therapy could bring up a lot of questions for you and your family. Here you can find answers to some of the questions you may be asking and guidance on preparing for treatment. </p>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section style={{backgroundColor: SassVars.paleBlue}}>
        <div className="row row--inner align-middle lg_mb-2">
          <div className="columns">
            <h4 className="h4 tc-viridian jost-med">Select a topic to learn more and start preparing for treatment.</h4>
          </div>
        </div>
        <div className="row row--inner align-center mb-2 hide-for-large">
          <div className="columns shrink">
            <p><small>&larr; swipe left to right to see all topics &rarr;</small></p>
          </div>
        </div>
        <Tabs tabchange={tabChange}>
          <Tabs.Pane name="Receiving Treatment" key="1">
            <div className="row row--inner">
              <div className="columns">
              <h2 className="h2 tc-barney jost-med">How will my child receive treatment?</h2>
              <p>The most common ways your child may receive treatment are: </p>
              </div>
            </div>
            <div className="row row--inner lg_mt-4 lg_mb-2 mt-2 mb-4">
              <div className="columns text-center small-12 large-4">
                <img src={byMouth} alt="Your child may receive neuroblastoma treatment by mouth"/>
                <p>By mouth (a pill or liquid to swallow)</p>
              </div>
              <div className="columns text-center small-12 large-4 lg_mt-4 mt-2">
                <img src={line} alt="Your child may receive neuroblastoma treatment intravenously"/>
                <p>Into a vein (<Link rel="glossary" to="/support-and-community/glossary/#intravenously">intravenously</Link> or IV) through a <Link rel="glossary" to="/support-and-community/glossary/#central-line-or-cl">central line</Link></p>
              </div>
              <div className="columns text-center small-12 large-4 mt-2">
                <img src={injection} alt="Your child’s neuroblastoma treatment may be given by injection"/>
                <p>Injection (a shot given into a muscle or under the skin)</p>
              </div>
            </div>
            <div className="row row--inner lg_mt-4 mt-4">
              <div className="columns">
                <h4 className="h4 tc-viridian jost-med">What is a central line?</h4>
                <p>A central line is a semipermanent catheter that provides a way for your child’s healthcare team to deliver <Link rel="glossary" to="/support-and-community/glossary/#chemotherapy">chemotherapy</Link> and other medicines intravenously (into a vein). The central line is surgically placed in your child’s body and connects directly to a central vein above the heart. The central line allows for:  </p>
                <ul className="ul--dotted">
                  <li>Multiple therapies to be given at the same time including chemotherapy and IV fluids</li>
                  <li><Link rel="glossary" to="/support-and-community/glossary/#supportive-care">Supportive care</Link> measures to be given quickly, such as pain medication, antibiotics, or IV nutrition</li>
                  <li>Blood to be drawn when needed without your child needing additional needle pokes</li>
                </ul>
                <p>The central line will stay in place throughout your child’s treatment journey. There are several types of central lines that may be placed, depending on your child’s anticipated treatment. </p>
              </div>
            </div>
            <div className="row row--inner lg_mt-4 mt-4">
              <div className="columns">
                <h4 className="h4 tc-viridian jost-med">Select a type of central line to learn more about how it's placed.</h4>
              </div>
            </div>
            <div className="row row--inner align-center mb-2 hide-for-large"><div className="columns shrink"><p><small>← swipe left to right to see all topics →</small></p></div></div>
            <TabbedCarousel className="lg_mt-4 mt-3">
              <TabbedCarousel.Pane name="PICC" key="1">
                <img src={picc} alt="Illustration of the central line placement for a peripherally inserted central catheter."/>
                <p><Link rel="glossary" to="/support-and-community/glossary/#peripherally-inserted-central-catheter-or-picc">PICC (peripherally inserted central catheter)</Link>: placed through a vein in your child’s arm that travels up to a large vein above the heart</p>
              </TabbedCarousel.Pane>
              <TabbedCarousel.Pane name="Broviac/Hickman" key="2">
                <img src={broviac} alt="Illustration of the placement for a Broviac/Hickman central line"/>
                <p><Link rel="glossary" to="/support-and-community/glossary/#broviac-hickman">Broviac/Hickman</Link>: placed through the chest of your child, into a vein above the heart</p>
              </TabbedCarousel.Pane>
              <TabbedCarousel.Pane name="Port" key="3">
                <img src={port} alt="Illustration of the placement for a Port central line"/>
                <p><Link rel="glossary" to="/support-and-community/glossary/#port">Port</Link>: placed underneath your child’s skin on their chest. This port is accessed with a needle through the skin to administer cancer therapy and/or <Link rel="glossary" to="/support-and-community/glossary/#supportive-care">supportive care</Link></p>
              </TabbedCarousel.Pane>
            </TabbedCarousel>
          </Tabs.Pane>
          <Tabs.Pane name="Clinical and Hospital Visits" key="2">
            <div className="row row--inner">
              <div className="columns">
                <h2 className="h2 tc-barney jost-med">When and where will my child receive treatment?</h2>
                <p>The frequency of treatment will depend on your child’s treatment plan. Treatment can be every day, every week, or every few weeks. Treatment is usually divided into <Link rel="glossary" to="/support-and-community/glossary/#cycle">cycles</Link>, which will allow your child time to rest and recover between treatments. Children with <Link rel="glossary" to="/support-and-community/glossary/#high-risk-neuroblastoma">high-risk neuroblastoma</Link> will have multiple cycles and phases of therapy. This will usually last for about 18 months. </p>
                <p>The majority of your child’s treatments will take place in the hospital or oncology clinic.</p>
              </div>
            </div>
            <div className="row row--inner lg_mt-3 mt-3">
              <div className="columns large-4 small-12 text-center large-text-left">
                <FancyImage url={oncology} alt="Oncology clinic visits"/>
              </div>
              <div className="columns mt-4">
                <h4 className="h4 tc-viridian jost-med">Oncology clinic visits</h4>
                <p>Your child may have many clinic visits during their treatment. Often, they meet with an oncologist or <Link rel="glossary" to="/support-and-community/glossary/#advanced-practice-provider-app-nurse-practitioner-or-physician-s-assistant">advanced practice provider</Link> (like a nurse practitioner) who will monitor your child’s physical condition and labs. The clinic team will provide consistency, and work together to coordinate your child’s treatment journey. Other reasons your child may go to the clinic include: </p>
                <ul className="ul--dotted">
                  <li>Blood or <Link rel="glossary" to="/support-and-community/glossary/#platelets">platelet</Link> transfusions</li>
                  <li>Short <Link rel="glossary" to="/support-and-community/glossary/#chemotherapy">chemotherapy</Link> infusions</li>
                  <li>Procedures to evaluate treatment</li>
                </ul>
                <p>It may be helpful to bring a notebook to each visit. Write down questions to ask and take notes, so you can remember important information. </p>
              </div>
            </div>
            <div className="row row--inner lg_mt-4 mt-4">
              <div className="columns">
                <h4 className="h4 tc-viridian jost-med">Hospital stays</h4>
                <p>Your child may need to stay in the hospital to receive treatment. The length of the stay will depend on the treatment regimen and how your child is doing. If available, request a visit from a Child Life Specialist to help your child learn more about what to expect, and how to deal with the stresses of illness and hospitalization. <Link to="/diagnosis/healthcare-team/">Read more about the role of Child Life Specialists in your child’s healthcare team.</Link></p>
                <p>Your child will be closely monitored before, during, and after each treatment. <Link rel="glossary" to="/support-and-community/glossary/#vital-signs">Vital signs</Link>, such as temperature, blood pressure, and heart rate, are checked. Blood work may also be checked. Along with treatment medications, like chemotherapy, your child may be given medications and fluids to help prevent side effects. Sometimes, you may end up staying in the hospital longer than you planned so it helps to be prepared. &nbsp;
                 <Link to="/supportive-care/">Find more information on side effects and supportive care during treatment.</Link></p>
              </div>
              <div className="columns large-4 small-12 text-center large-text-left mt-2">
                <FancyImage url={hospitalStay} alt="Hospital stays"/>
              </div>
            </div>
            <div className="row row--inner lg_mt-4 mt-4">
              <div className="columns">
                <h4 className="h4 tc-viridian jost-med">What should I bring to the hospital?</h4>
                <p>Many parents have a bag packed for hospital stays at all times. This is a helpful way to be prepared for unplanned hospital stays, such as when your child gets a <Link rel="glossary" to="/support-and-community/glossary/#fever">fever</Link>. Parents often recommend packing:</p>
              </div>
            </div>
            <div className="row row--inner lg_mt-3 mt-2 mb-2">
              <div className="columns">
              {parseInt(active) === 2 &&
                <Slider className="grid-slider grid-slider--large-3" {...settings}>
                  <div className="text-center">
                    <img src={pillowBlanket} alt="title" />
                    <p className="mt-2">Pillow and blanket from home (for both parent and child)</p>
                  </div>
                  <div className="text-center">
                    <img src={snacks} alt="title"/>
                    <p className="mt-2">Favorite snacks and drinks</p>
                  </div>
                  <div className="text-center">
                    <img src={chargers} alt="title"/>
                    <p className="mt-2">Chargers for phone, tablet, and computer</p>
                  </div>
                  <div className="text-center">
                    <img src={favToy} alt=""/>
                    <p className="mt-2">Child’s favorite toy or stuffed animal</p>
                  </div>
                  <div className="text-center">
                    <img src={comfyClothes} alt=""/>
                    <p className="mt-2">Comfortable clothing</p>
                  </div>
                  <div className="text-center">
                    <img src={toiletries} alt=""/>
                    <p className="mt-2">Toiletries</p>
                  </div>
                </Slider>
              }
              </div>
            </div>
          </Tabs.Pane>
          <Tabs.Pane name="Managing at Home" key="3">
            <div className="row row--inner">
              <div className="columns">
                <h2 className="h2 tc-barney jost-med">Managing home life</h2>
                <p>Getting used to a new “normal” can be difficult at first. Your entire family may be impacted by the new schedule of clinic visits, <Link rel="glossary" to="/support-and-community/glossary/#chemotherapy">chemotherapy</Link>  admissions, and unexpected hospital stays.</p>
              </div>
            </div>
            <div className="row row--inner lg_mt-4 mt-4">
              <div className="columns">
                <h4 className="h4 tc-viridian jost-med">Staying organized </h4>
                <p>You may feel exhausted by all the new information you get. Here are a few tips that other caregivers and families have found useful in helping them stay organized and prepared at each step.</p>
              </div>
            </div>
            <div className="row row--inner lg_mt-4 mt-2">
              <div className="columns small-12 large-2 text-center">
                <img src={calendar} alt="calendar"/>
              </div>
              <div className="columns mt-2">
                <ul className="ul--dotted">
                  <li>
                    <strong>Use a calendar</strong>
                    <ul>
                      <li>Create a family calendar to help you keep track of a busy schedule. Understanding the order of events in small timeframes can help you to plan ahead before clinic appointments and hospital admissions. It may also help you to anticipate when you may need to take time off of work and schedule childcare for siblings</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row row--inner lg_mt-2 mt-2">
              <div className="columns small-12 large-2 text-center">
                <img src={keepNotes} alt="calendar"/>
              </div>
              <div className="columns mt-2">
                <ul className="ul--dotted">
                  <li>
                    <strong>Keep treatment records and notes</strong>
                    <ul>
                      <li>A treatment journal or notebook may help you remember tests, procedures, treatments, medications, and side effects. It is also a good place to write down questions for your oncologist. <Link data-gtm-event-category="External Link" data-gtm-event-action="Click" data-gtm-event-label="Managing at Home - Learn How to Track" to="https://www.cncfhope.org/support-and-community/parent-handbook-by-chapters/" target="_blank" rel="noopener">Learn how to start tracking your child's treatment <small><FontAwesomeIcon icon={faExternalLinkAlt} /></small></Link>.</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row row--inner lg_mt-2 mt-2">
              <div className="columns small-12 large-2 text-center">
                <img src={askForHelp} alt="calendar"/>
              </div>
              <div className="columns mt-2">
                <ul className="ul--dotted">
                  <li>
                    <strong>Ask for help</strong>
                    <ul>
                      <li>Simple everyday responsibilities like cooking, taking care of a pet, or driving children to school may now be interrupted. What type of support system do you have? Do you have family nearby? Do you have community support, such as belonging to a religious organization?</li>
                      <li>Don’t be afraid to ask for help from family, friends, or the community to help manage the day-to-day. The reality is that most people want to help but don’t know how. The neuroblastoma journey is a hard one to go through alone. Having a support system can help lighten the heavy load</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row row--inner lg_mt-2 mt-2">
              <div className="columns small-12 large-2 text-center">
                <img src={hospitalRoad} alt="calendar"/>
              </div>
              <div className="columns mt-2">
                <ul className="ul--dotted">
                  <li>
                    <strong>Plan for stays away from home</strong>
                    <ul>
                      <li>If your child requires treatment at a hospital in another city then you may find yourself away from home, and even staying overnight at times</li>
                      <li>Discuss roles with your loved ones early on. This can help with sharing the responsibilities of being household caretaker and caregiver for your child. In some instances, it may be helpful to have a short list of family or friends who you trust to watch over your other children</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row row--inner lg_mt-2 mt-4">
              <div className="columns small-12 large-4 text-center large-text-left">
                <FancyImage url={insuranceCost} alt="Managing neuroblastoma treatment costs and insurance"/>
              </div>
              <div className="columns">
                <h3 className="h2 tc-barney jost-med">Managing neuroblastoma treatment costs and insurance</h3>
                <p>The cost of cancer treatment can often be a source of stress for families. Your oncology social worker may be able to help address your concerns. </p>
                <p><Link to="/support-and-community/support-groups/" className="btn--primary btn--inline">To learn more about organizations that may be able to help with the cost of your child’s treatment, go to the Neuroblastoma Support and Advocacy Groups page </Link></p>
              </div>
            </div>
          </Tabs.Pane>
        </Tabs>
      </Section>


      <InteriorPageFooter>

      <UpNextCard topic="Getting to Know Your Treatment Team" title="Who is involved in my child's treatment?" lead="Meet the healthcare team members who may care for your child." url="/diagnosis/healthcare-team/" />

      </InteriorPageFooter>


    </Layout>
  )
}

export default PreparingForTreatmentPage
